import { FC } from "react";
import Link from "next/link";
import { NavItems } from "../Navbar/NavItems/NavItems";
import styles from "./fotter.module.scss";
import Image from "next/image";
import logoSSL from "../../../public/img/ssl-logo.png";
import logoING from "../../../public/img/inc5000Logo.png";
import logoBBB from "../../../public/svg/BBB-LOGO-SVG.svg";
//import NewsLetterSubscribe from "../NewsLetterSubscribe/NewsLetterSubscribe";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import dynamic from "next/dynamic";
import Script from "next/script";
import useHover from "../../hooks/useHover";

const NewsLetterSubscribe = dynamic(
	() => import("../NewsLetterSubscribe/NewsLetterSubscribe"),
);

const Footer: FC = () => {
	const [hoverBBBlogo, isHoverBBBlogo] = useHover<HTMLDivElement | any>();

	return (
		<>
			<span id="FooterNewsLetter"></span>
			<NewsLetterSubscribe />
			<footer>
				<div className="container">
					<div className="footer-menu">
						<ul className="footer-menu-container-link">
							{NavItems.map((item, index) => {
								return (
									<li className="footer-nav-item" key={index}>
										<Link href={item.url}>
											<a>{item.label}</a>
										</Link>
									</li>
								);
							})}
						</ul>
						<div className="footer-nav-hr-line"></div>
					</div>

					<div className="footer-info">
						<div className="leftside">
							<a href="tel:1-800-797-0385" className="first">
								1-800-797-0385
							</a>
							<a href="mailto:sales@buylowwarehouse.com" className="email">
								sales@buylowwarehouse.com
							</a>
							<p className="copyright">© BuyLow Warehouse llc</p>

							<div className="rightside">
								<div className="social">
									<p className="follow-us">Follow us</p>
									<div className="social-icons-holders">
										<a
											href="https://www.facebook.com/buylowwarehouse"
											target="_blank"
											rel="noreferrer"
										>
											<i className="fab fa-facebook-f"></i>
										</a>
										<a
											href="https://twitter.com/buylowwarehouse"
											target="_blank"
											rel="noreferrer"
										>
											<i className="fab fa-twitter"></i>
										</a>
										<a
											href="https://www.linkedin.com/company/buy-low-warehouse"
											target="_blank"
											rel="noreferrer"
										>
											<i className="fab fa-linkedin"></i>
										</a>
									</div>
								</div>
							</div>
						</div>

						<div className={styles.rigthSide}>
							<div className={styles.bbbLogo}>
								<a
									target="_blank"
									rel="noreferrer"
									href="https://www.bbb.org/us/tn/brentwood/profile/wholesalers-and-distributors/buylow-warehouse-0573-37366615#bbbseal"
									title="BuyLow Warehouse is a BBB Accredited Wholesaler and Distributor in Brentwood, TN"
								>
									<div className={styles.bbbLogoBOx}>
										<div
											className={`${styles.bbbLogoImage} ${
												isHoverBBBlogo ? styles.activeBBBlogo : ""
											}`}
											ref={hoverBBBlogo}
										></div>

										<div
											className={`${styles.bbbLogoImageTest} ${
												isHoverBBBlogo ? styles.activeBBBlogoTest : ""
											}`}
										>
											<Image src={logoBBB} alt="Logo BBB" />
										</div>

										<div className={styles.BBBlogoRating}>Rating: A+</div>

										<div
											className={`${styles.bbbLogoImageText} ${
												isHoverBBBlogo ? styles.activeBBBlogoText : ""
											}`}
										>
											<span>Since</span>
											<span className={styles.bbbLogoImageTextBolt}>
												Aug 2022
											</span>
											<span>As of</span>
											<span className={styles.bbbLogoImageTextBolt}>
												Sep 16, 2022
											</span>
											<span className={styles.bbbLogoImageTextLink}>
												Click For
											</span>
											<span className={styles.bbbLogoImageTextLink}>
												Profile
											</span>
										</div>
									</div>
								</a>
							</div>
							<div className={styles.sslLogo}>
								<Link
									href={
										"/press-release/Inc-5000-Ranks-BuyLow-Warehouse-As-228-For-2022"
									}
									passHref
								>
									<Image src={logoING} alt="Logo ING" />
								</Link>
							</div>

							<div className={styles.sslLogo}>
								<Image src={logoSSL} alt="Logo SSL" />
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
};
export default Footer;

export const NavItems = [
	{
		label: "About",
		url: "/about",
		active: false,
	},
	{
		label: "Inventory",
		url: "/inventory",
		active: false,
	},
	{
		label: "Resources",
		url: "/resources",
		active: false,
	},
	{
		label: "FAQ",
		url: "/faq",
		active: false,
	},
	{
		label: "Reviews",
		url: "/reviews",
		active: false,
	},
	{
		label: "Contact",
		url: "/contact",
		active: true,
	},
];
